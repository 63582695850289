<template>
	<div class="Theorder">
		<div class="Theorder-T">
			<div class="Theorder-TL">
				<Breadcrumb></Breadcrumb>
				<div class="Theorder-searchs">
					<div class="Theorder-searchs1">
						<inputs @inputs="inputValue" :inputShow="true" inputPlace="请输入名称查询数据"></inputs>
					</div>
					<div class="Theorder-tit-mai1-btn">
						<!-- @click="clickNew()" -->
						<!-- <el-button type="danger" icon="el-icon-search">查询</el-button> -->
					</div>
				</div>
			</div>
			<div class="Theorder-TR">
				<div class="Theorder-searchsMai">
					<!-- <el-button type="danger" icon="el-icon-plus" @click="clickNew()">新增</el-button> -->
				</div>
			</div>
		</div>
		<div class="Theorder-B">
			<div class="Theorder2">
				<el-button v-permission="'GoodsOrder:Add'" @click="clickNew()" icon="el-icon-plus" style="margin-bottom: 20px;">新增订单</el-button>
				<el-table :data="tableData" border style="width: 100%" size="small">
					<template slot="empty">
						<noData></noData>
					</template>
					<el-table-column prop="T_orderid" label="订单号" :show-overflow-tooltip="true" width="150">
					</el-table-column>
					<el-table-column prop="T_outorderid" label="出库订单号" :show-overflow-tooltip="true">
					</el-table-column>
					<el-table-column prop="T_name" label="设备编号" :show-overflow-tooltip="true">
					</el-table-column>
					<el-table-column prop="T_receiving" label="收货单位" :show-overflow-tooltip="true" width="250">
					</el-table-column>
					<el-table-column label="起运时间" :show-overflow-tooltip="true" width="150">
						<template slot-scope="scope">
							{{scope.row.T_start_Ut| Time(scope.row.T_start_Ut)}}
						</template>
					</el-table-column>
					<el-table-column label="到达时间" :show-overflow-tooltip="true" width="150">
						<template slot-scope="scope">
							{{scope.row.T_end_Ut| Time(scope.row.T_end_Ut)}}
						</template>
					</el-table-column>
					<el-table-column prop="address" label="操作" fixed="right" width="250">
						<template slot-scope="scope">
							<el-button v-permission="'GoodsOrder:Pdf'" size="small" @click="allpir('a',scope.row)">PDF下载</el-button>
							<el-button v-permission="'GoodsOrder:Edit'" size="small" @click="allpir('b',scope.row)">编辑</el-button>
							<el-button v-permission="'GoodsOrder:Del'" size="small" @click="allpir('c',scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="Theorder-pagination">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
					:current-page="pages.page" :page-size="pages.page_z" layout="total, prev, pager, next, jumper"
					:total="total">
				</el-pagination>
			</div>
		</div>
		
		<el-dialog :title="titles" :visible.sync="dialogVisible" width="50%" :append-to-body="true">
			<div class="NewUser">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="订单号" prop="T_orderid">
						<el-input v-model="ruleForm.T_orderid" placeholder="请输入订单号"></el-input>
					</el-form-item>
					<el-form-item label="出库订单号" prop="T_outorderid">
						<el-input v-model="ruleForm.T_outorderid" placeholder="请输入出库订单号"></el-input>
					</el-form-item>
					<el-form-item label="设备编号" prop="T_sn">
						<el-select v-model="ruleForm.T_sn" placeholder="请选择设备编号">
							<el-option v-for="item in options" :key="item.value" :label="item.T_devName" :value="item.T_sn">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="收货单位" prop="T_receiving">
						<el-input v-model="ruleForm.T_receiving" placeholder="请输入收货单位"></el-input>
					</el-form-item>
					<el-form-item label="运输时间" prop="T_time">
						<el-date-picker v-model="ruleForm.T_time" type="datetimerange"
							value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期"
							end-placeholder="结束日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="详情" prop="T_text">
						<el-input type="textarea" v-model="ruleForm.T_text"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="danger" @click="submitForm('ruleForm')">立即提交</el-button>
						<el-button @click="dialogVisible=false">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		GoodsOrderList,
		GoodsOrderAdd,
		DeviceList,
		GoodsOrderDel,
		GoodsOrderEdit,
		GoodsOrderPDF
	} from '../../api/instrument/Theorder.js'
	import {formatDate} from '../../utils/Times.js'
	import Axios from 'axios'
	export default {
		data() {
			return {
				total: 0,
				pages: {
					T_name: '',
					page: 1,
					page_z: 10
				},
				titles:'',
				ruleForm: {
					T_orderid: '', //订单号
					T_outorderid: '', //出库订单号
					T_sn: '', //设备sn
					T_receiving: '', //收货单位
					T_time: [], //运输时间
					T_text: '' //详情
				},
				rules: {
					T_orderid: [{
						required: true,
						message: '请输入订单号',
						trigger: 'blur'
					}, ],
					T_outorderid: [{
						required: true,
						message: '请输入出库订单号',
						trigger: 'blur'
					}],
					T_sn: [{
						required: true,
						message: '请选择设备编号',
						trigger: 'change'
					}],
					T_receiving: [{
						required: true,
						message: '请输入收货单位',
						trigger: 'blur'
					}],
					T_time: [{
						required: true,
						message: '请选择运输时间',
						trigger: 'change'
					}],
					T_text: [{
						required: true,
						message: '请填写订单详情',
						trigger: 'blur'
					}]
				},
				options: [],
				dialogVisible: false,
				Page4: 5, //当前页
				loading: false,
				formInline: {
					user: '',
					region: ''
				},
				sta:null,
				tableData: []
			}
		},
		mounted() {
			this.GetTheorderApi()
			this.GetDeviceListApi()
		},
		methods: {
			GetTheorderApi() {
				GoodsOrderList(this.pages).then(res => {
					if (res.data.Code === 200 && res.data.Msg === 'ok!') {
						////console.log('liebiao',res.data.Data.Data)
						this.total = res.data.Data.Num
						this.tableData = res.data.Data.Data
					}
				})
			},
			GetDeviceListApi() { //设备编号select框
				var that = this
				DeviceList({
					page:1,
					page_z:9999999
				}).then(res => {
					if(res.data.Code===200 && res.data.Msg==="ok!"){
						that.options = res.data.Data.Device_lite
					}
				})
			},
			inputValue(e) {
				this.pages.T_name = e
				this.pages.page = 1
				this.GetTheorderApi()
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						var obj = {...this.ruleForm}
						obj.T_time =obj.T_time[0] + '|' + obj.T_time[1]
						////console.log('提交', obj,this.sta)
						var that = this
						if(that.sta == 'a'){
							////console.log('新增提交')
							that.SetAdd(obj)
						}else{
							////console.log('编辑提交')
							that.SetEdit(obj)
						}
					} else {
						this.$message.error('请完善必填信息在提交')
						return false;
					}
				});
			},
			SetEdit(e){
				var that = this
				GoodsOrderEdit(e).then(res=>{
					////console.log('res',res)
					if(res.data.Code===200 && res.data.Msg==="ok!"){
						that.pages.page = 1
						that.GetTheorderApi()
						that.$message.success('编辑订单成功！')
						that.dialogVisible = false
					}
				})
			},
			SetAdd(obj){
				var that = this
				GoodsOrderAdd(obj).then(res=>{
					////console.log('添加',res)
					if(res.data.Code===200 && res.data.Msg==="ok!"){
						that.pages.page = 1
						that.GetTheorderApi()
						that.$message.success('新增订单成功！')
						that.dialogVisible = false
					}
				})
			},
			clickNew() {
				this.dialogVisible = true
				this.sta = 'a'
				this.titles = '新增订单'
				if(this.$refs.ruleForm){//为了验证第一次触发表单验证，第二次打开还是触发
					this.$refs.ruleForm.resetFields();
				}
			},
			handleSizeChange(e) { //每页多少条
				this.pages.page_z = e
				this.GetTheorderApi()
			},
			handleCurrentChange(e) { //跳转页
				this.pages.page = e
				this.GetTheorderApi()
			},
			allpir(i, item) {
				var that = this
				if (i === 'a') { //
					const loading = this.$loading({
						lock: true,
						text: '正在整理数据，请稍后...',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					GoodsOrderPDF({T_id:item.Id}).then(res=>{
						loading.close()
						Axios({
							method: 'get',
							url: res.data.Data,
							responseType: 'blob'
						}).then(resBlob => {
							const FileSaver = require('file-saver')
							const blob = new Blob([resBlob.data], { type: 'application/pdf;charset=utf-8' }) // 此处type根据你想要的
							FileSaver.saveAs(blob, new Date().getTime()) // 下载的name文件名
							this.$message.success('PDF文件下载成功');
						});
					})
				} else if (i === "b") { //编辑
					if(this.$refs.ruleForm){//为了验证第一次触发表单验证，第二次打开还是触发
						this.$refs.ruleForm.resetFields();
					}
					this.dialogVisible = true
					this.sta = 'b'
					this.titles = '编辑订单'
					this.ruleForm.T_time[0] = formatDate(item.T_start_Ut)
					this.ruleForm.T_time[1] = formatDate(item.T_end_Ut)
					this.ruleForm.T_text = item.T_text
					this.ruleForm.T_id = item.Id
					this.ruleForm.T_sn = item.T_sn
					this.ruleForm.T_receiving = item.T_receiving
					this.ruleForm.T_outorderid = item.T_outorderid
					this.ruleForm.T_orderid = item.T_orderid
					
				} else if (i === "c") { //同步大屏
					this.$confirm('删除操作，是否继续？', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
						center: true
					}).then(() => {
						that.SetdelApi(item.Id)
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '删除取消'
						});
					});
					
					
				}
			},
			SetdelApi(id){
				var that = this
				GoodsOrderDel({T_id:id}).then(res=>{
					////console.log('删除',res)
					if(res.data.Code===200 && res.data.Msg=="ok!"){
						that.GetTheorderApi()
						that.$message.success('删除成功')
					}
				})
			},
			onSubmit() {
				////console.log('submit!');
			}
		},
		filters: {
			Time: function(time) { //时间戳转换
				if (time) {
					var date = new Date(time); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
					let y = date.getFullYear();
					let MM = (date.getMonth() + 1).toString().padStart(2, '0');
					let d = date.getDate().toString().padStart(2, '0');
					let h = date.getHours().toString().padStart(2, '0');
					let m = date.getMinutes().toString().padStart(2, '0');
					let s = date.getSeconds().toString().padStart(2, '0');
					return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
				} else {
					return '0000-00-00  00-00-00'
				}
			},
		},
	}
</script>

<style lang="scss">
	.Theorder {
		user-select: none;

		.Theorder-T {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: rgba(#fff, 1);
			height: 70px;
			padding: 15px 10px;
			box-shadow: 5px 5px 10px -10px #000;

			.Theorder-TL {
				display: flex;
				align-items: center;

				.Theorder-searchs {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: 20px;

					.Theorder-tit-mai1-btn {}

					.Theorder-searchs1 {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}

			.Theorder-searchsMai {
				display: flex;
				align-items: center;
				cursor: pointer;

				.Theorder-searchsMai1 {}
			}
		}
		.Theorder-B{
			background: rgba(#fff, 1);
		}
		.Theorder2 {
			
			// border-radius: 10px;
			overflow: hidden;
			margin: 10px;
			padding: 20px;
		}

		.Theorder-pagination {
			// background: rgba(#31353e,1);
			padding:50px 20px;
			margin: 10px;
			border-radius: 2px;
			display: flex;
			// .el-pagination.is-background .btn-next,
			// .btn-prev,
			// .el-pagination.is-background .el-pager li {
			// 	//未选中
			// 	margin: 0 5px;
			// 	background-color: #222;
			// 	color: #fff;
			// 	min-width: 30px;
			// 	border-radius: 2px;
			// }

			// .el-pagination.is-background .el-pager li:not(.disabled).active {
			// 	//选中
			// 	background-color: #fff;
			// 	color: #222;
			// }

			// .el-pagination__total {
			// 	//总total
			// 	margin-right: 10px;
			// 	font-weight: 400;
			// 	// color: #fff;
			// }

			// .el-pagination__jump {
			// 	//跳转页
			// 	margin-left: 24px;
			// 	font-weight: 400;
			// 	// color: #fff;
			// }
		}
	}
</style>
