// 传感器分类接口
import {
	axios
} from '../../utils/http.js'

const ClassApi = {
	GoodsOrderList: '/v3/GoodsOrder/List',//订单管理列表
	GoodsOrderAdd: '/v3/GoodsOrder/Add',//添加
	GoodsOrderPDF: '/v3/GoodsOrder/PDF',//到处pdf
	GoodsOrderDel: '/v3/GoodsOrder/Del',//删除订单
	GoodsOrderEdit: '/v3/GoodsOrder/Edit',//编辑订单
	
	DeviceList: '/v3/Device/List',//设备管理列表
	
}
//编辑订单
export function GoodsOrderEdit(data) {
	return axios({
		url: ClassApi.GoodsOrderEdit,
		method: 'post',
		data:data
	})
}
//设备管理列表
export function GoodsOrderDel(data) {
	return axios({
		url: ClassApi.GoodsOrderDel,
		method: 'post',
		data:data
	})
}

//设备管理列表
export function GoodsOrderPDF(data) {
	return axios({
		url: ClassApi.GoodsOrderPDF,
		method: 'post',
		data:data
	})
}
//设备管理列表
export function DeviceList(data) {
	return axios({
		url: ClassApi.DeviceList,
		method: 'post',
		data:data
	})
}
//添加
export function GoodsOrderAdd(data) {
	return axios({
		url: ClassApi.GoodsOrderAdd,
		method: 'post',
		data:data
	})
}

//订单管理列表
export function GoodsOrderList(data) {
	return axios({
		url: ClassApi.GoodsOrderList,
		method: 'post',
		data:data
	})
}
